<template>
    <div class="caja-header d-flex flex-row justify-content-end align-items-center">
        <router-link to="/confirm" class="confirma-correo" v-if="correoConfirmacion === false">Confirma tu email</router-link>
        <div class="nombre-y-foto d-flex flex-row justify-content-center align-items-center" @click="activarDesplegable">
            <p class="texto-nombre">{{nombre}} {{apellido}}</p>
            <div class="cuadro-perfil-iniciales d-flex flex-row justify-content-center align-items-center" v-if="bulPerfil === false">{{urlImagen}}</div>
            <div class="cuadro-contenedor-espacio d-flex align-items-center justify-content-center" v-if="bulPerfil === true">
                <div class="circulo-perfil2 d-flex align-items-center justify-content-center">
                    <div class="cuadro-perfil-imagen" v-if="bulPerfil === true" :style="`height:${alturaImagen}%; width:${anchoImagen}%; margin-top:${topImagen}%; margin-left:${izquierdaImagen}%; background-image:url(${urlImagen});`"></div>
                </div>
            </div>
        </div>
        <div class="caja-desplegable d-flex justify-content-start align-items-center flex-column" v-if="bulDesplegable === true">
            <router-link to="/app/profile" class="arreglo-texto"><p class="texto-header-desplegable">Inicio</p></router-link>
            <router-link to="/app/billetera" class="arreglo-texto"><p class="texto-header-desplegable">Billetera</p></router-link>
            <router-link to="/app/tickets" class="arreglo-texto"><p class="texto-header-desplegable">Tickets</p></router-link>
            <router-link to="/app/configuracion" class="arreglo-texto"><p class="texto-header-desplegable">Configuración</p></router-link>
            <p class="texto-header-desplegable" @click="cerrarSesion">Cerrar sesión</p>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex"
    import axios from 'axios'

    export default {
        name: "App",
        data(){
            return{
                nombre:'',
                apellido:'',
                urlImagen:'',
                correoConfirmacion:false,
                bulPerfil: false,
                bulDesplegable: false,

                //imagen
                alturaImagen: 100,
                anchoImagen:100,
                izquierdaImagen:0,
                topImagen:0
            }
        },
        methods:{
            //carga de informacion de la persona en header
            async loadHeader(){
                //consulta de apirest
                let userHeader = await axios.get(`${this.server}/information/header`, {

                    params: {token: localStorage.getItem('token')}}, 

                    {headers: {'Content-Type': 'application/json'}
                });

                //token expiro

                if(userHeader.data.message === "Error en validación"){
                    this.$router.push({path: '/login'})
                }

                //sustitucion de informacion

                this.anchoImagen = userHeader.data.ancho
                this.alturaImagen = userHeader.data.alto
                this.izquierdaImagen = userHeader.data.izquierda
                this.topImagen = userHeader.data.arriba

                //condicional para ver si el usuario ya elegido una foto de perfil

                if(userHeader.data.url === null || userHeader.data.url === false || userHeader.data.url === 'false'){
                    this.bulPerfil = false
                    this.urlImagen = `${userHeader.data.nombre[0]}${userHeader.data.nombre[0]}`
                }else{
                    this.bulPerfil = true
                    this.urlImagen = userHeader.data.url
                }

                //declaracion de variables

                let nombreEspacio
                let apellidoEspacio

                //pasamos la informacion suministrada por la api
                //comprobamos los espacios y saltadas de linea del campo nombre y apellido para acomodarlo correctamente en el header

                for(let i =0;i<10;i++){
                    if(userHeader.data.nombre[i] === ' ' || userHeader.data.nombre[i] === `
`){
                        nombreEspacio = i
                    }
                    if(userHeader.data.apellido[i] === ' ' || userHeader.data.apellido[i] === `
`){
                        apellidoEspacio = i 
                    }
                }

                //buscamos el primer nombre dentro de las 10 letras y declaramos

                if(nombreEspacio > 10){
                    this.nombre = userHeader.data.nombre.substring(0,10)
                }else{
                    this.nombre = userHeader.data.nombre.substring(0,nombreEspacio)
                }

                if(apellidoEspacio > 10){
                    this.apellido = userHeader.data.apellido.substring(0,10)
                }else{
                    this.apellido = userHeader.data.apellido.substring(0,apellidoEspacio)
                }

                
                this.correoConfirmacion = userHeader.data.confirmacion
            },

            //caja desplegable

            activarDesplegable(){
                if(this.bulDesplegable === false){
                    this.bulDesplegable = true
                }else{
                    this.bulDesplegable = false
                }
            },

            //cerrar sesion

            cerrarSesion(){
                localStorage.setItem('token','')
                this.$router.push({path: '/login'})
            }

        },
        created () {
            this.loadHeader();
        },
        computed:{
        ...mapState(["token", "server"]),
        }
    }
</script>

<style scoped>
    .caja-desplegable{
        position: absolute;
        top: 90px;
        width: 150px;
        height: 245px;
        right: 170px;
        padding-top:20px;
        background: #110133;
        border-radius: 10px;
    }
    .arreglo-texto{
        text-decoration: none;
        width: 100%;
    }
    .texto-header-desplegable{
        font-family: Rubik;
        font-weight: bold;
        padding-top:10px ;
        padding-bottom: 10px;
        margin: 0px;
        font-size: 14px;
        color: white;
        width: 100%;
        text-align: center;
        text-decoration: none;
        cursor: pointer;
    }
    .texto-header-desplegable:hover{
        background:#297f87be ;
    }
    .caja-header{
        background: #110133;
        position: fixed;
        height: 80px;
        width: 100%;
        top: 0px;
        left: 0px;
        z-index: 10;
    }
    .confirma-correo{
        background: #297f87be;
        text-align: center;
        font-family: Rubik;
        font-size: 20px;
        margin-right:100px ;
        text-decoration: none;
        color: white;
        padding:7.5px 60px;
        border-radius: 10px;
    }
    .nombre-y-foto{
        margin-right: 90px;
        width: 300px;
        cursor: pointer;
    }
    .texto-nombre{
        text-align: center;
        font-family: Rubik;
        font-weight:bold ;
        font-size: 16px;
        color: white;
        margin-top:17.5px ;
    }
    .cuadro-perfil-iniciales{
        width: 50px;
        height: 50px;
        border-radius:50%;
        background: #297f87be;
        text-align: center;
        font-family: Rubik;
        font-weight:bold ;
        font-size: 16px;
        margin-left:20px ;
        color: white;
    }
    .cuadro-perfil-imagen{
        border-radius:50%;
        background-position: center;
        background-repeat:no-repeat ;
        background-size: 100% 100%;
        background-color: #297f87be;
        margin-left:20px ;
        user-select: none;
        position: absolute;
    }
    .cuadro-contenedor-espacio{
        width:50px;
        height: 50px;
        margin-left:20px ;
    }
    .circulo-perfil2{
        position: relative;
        width: 100%;
        height: 100%;
        background: #297F87;
        border-radius:50% ;
        overflow: hidden;
    }
</style>